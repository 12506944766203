import {BookOpen, Clock, Globe, Hourglass, Image, Lock, QrCode, User} from 'lucide-react';
import i18next from './i18n';

export const featureList = [
  {
    title: i18next.t('homepage.features.feature_list.reminderPages.title'),
    description: i18next.t('homepage.features.feature_list.reminderPages.description'),
    icon: <BookOpen className="h-10 w-10 text-blue-500"/>
  },
  {
    title: i18next.t('homepage.features.feature_list.qrCodeAccess.title'),
    description: i18next.t('homepage.features.feature_list.qrCodeAccess.description'),
    icon: <QrCode className="h-10 w-10 text-blue-500"/>
  },
  {
    title: i18next.t('homepage.features.feature_list.lifeline.title'),
    description: i18next.t('homepage.features.feature_list.lifeline.description'),
    icon: <Hourglass className="h-10 w-10 text-blue-500"/>
  },
  {
    title: i18next.t('homepage.features.feature_list.protect.title'),
    description: i18next.t('homepage.features.feature_list.protect.description'),
    icon: <Lock className="h-10 w-10 text-blue-500"/>
  },
  {
    title: i18next.t('homepage.features.feature_list.personalPage.title'),
    description: i18next.t('homepage.features.feature_list.personalPage.description'),
    icon: <User className="h-10 w-10 text-blue-500"/>
  },
  {
    title: i18next.t('homepage.features.feature_list.media.title'),
    description: i18next.t('homepage.features.feature_list.media.description'),
    icon: <Image className="h-10 w-10 text-blue-500"/>
  },
  {
    title: i18next.t('homepage.features.feature_list.availability.title'),
    description: i18next.t('homepage.features.feature_list.availability.description'),
    icon: <Clock className="h-10 w-10 text-blue-500"/>
  },
  {
    title: i18next.t('homepage.features.feature_list.availableEverywhere.title'),
    description: i18next.t('homepage.features.feature_list.availableEverywhere.description'),
    icon: <Globe className="h-10 w-10 text-blue-500"/>
  }
];
