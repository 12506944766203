import React from 'react';

interface ImageContainerProps {
    children: React.ReactNode;
    text: string;
}

const ImageContainer: React.FC<ImageContainerProps> = ({children, text}) => {
    return (
        <div className="relative isolate">
            <div className="mx-auto max-w-4xl px-6 lg:px-8">
                <div className="mt-10 flow-root sm:mt-20">
                    <div className="rounded-xl bg-gray-900/5 p-4 pt-2 ring-1 ring-inset ring-gray-900/10 lg:rounded-2xl">
                        <h1 className="text-center font-bold text-lg lg:text-3xl text-primary mb-2">
                            {text}
                        </h1>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ImageContainer;
