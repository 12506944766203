import { buttonVariants } from '@/Components/ui/button';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/Components/ui/tooltip';
import { cn } from '@/lib/utils';
import { ArrowRight, Check, HelpCircle, Minus } from 'lucide-react';
import { Link } from '@inertiajs/react';
import MaxWidthWrapper from '@/Components/MaxWidthWrapper';
import { useTranslation } from 'react-i18next';
import {PageProps} from "@/types";
import UpgradeButton from "@/Components/Buttons/UpgradeButton";

export const PLANS = [
  {
    name: 'Free',
    slug: 'free',
    price: {
      amount: 0,
      priceIds: {
        test: '',
        production: ''
      }
    }
  },
  {
    name: 'Pro',
    slug: 'pro',
    price: {
      amount: 5,
      priceIds: {
        test: 'price_1NuEwTA19umTXGu8MeS3hN8L',
        production: ''
      }
    }
  }
];

const Pricing = ({ auth }: PageProps) => {
  const { t } = useTranslation();

  const pricingItems = [
    {
      plan: t('homepage.pricing.free.title'),
      tagline: t('homepage.pricing.free.description'),
      quote: t('homepage.pricing.free.quote'),
      features: [
        {
          text: t('homepage.pricing.free.features.pageLimit.text'),
          footnote: t('homepage.pricing.free.features.pageLimit.footnote')
        },
        {
          text: t('homepage.pricing.free.features.dataLimit.text'),
          footnote: t('homepage.pricing.free.features.dataLimit.footnote')
        },
        {
          text: t('homepage.pricing.free.features.design.text')
        },
        {
          text: t('homepage.pricing.pro.features.lifeline.text'),
          negative: false
        },
        {
          text: t('homepage.pricing.free.features.prioritySupport.text'),
          negative: true
        }
      ]
    },
    {
      plan: t('homepage.pricing.pro.title'),
      tagline: t('homepage.pricing.pro.description'),
      quote: t('homepage.pricing.pro.quote'),
      features: [
        {
          text: t('homepage.pricing.pro.features.pageLimit.text'),
          footnote: t('homepage.pricing.pro.features.pageLimit.footnote')
        },
        {
          text: t('homepage.pricing.pro.features.dataLimit.text'),
          footnote: t('homepage.pricing.pro.features.dataLimit.footnote')
        },
        {
          text: t('homepage.pricing.pro.features.lifeline.text'),
          footnote: t('homepage.pricing.pro.features.lifeline.footnote')
        },
        {
          text: t('homepage.pricing.pro.features.prioritySupport.text')
        }
      ]
    }
  ];

  return (
    <MaxWidthWrapper className="mb-8 mt-24 text-center max-w-5xl">
      <div className="mx-auto mb-10 sm:max-w-lg">
        <h1 className="text-6xl font-bold sm:text-7xl">
          {t('homepage.pricing.title')}
        </h1>
        <p className="mt-5 text-gray-600 sm:text-lg">
          {t('homepage.pricing.description')}
        </p>
      </div>

      <div className="pt-12 grid grid-cols-1 gap-10 lg:grid-cols-2">
        <TooltipProvider>
          {pricingItems.map(({ plan, tagline, quote, features }) => {
            const price = PLANS.find((p) => p.slug === plan.toLowerCase())?.price.amount || 0;

            return (
              <div
                key={plan}
                className={cn(
                  'relative rounded-2xl bg-white shadow-lg flex flex-col h-full',
                  {
                    'border-2 border-blue-600 shadow-blue-200': plan === 'Pro',
                    'border border-gray-200': plan !== 'Pro'
                  }
                )}
              >
                {plan === 'Pro' && (
                  <div className="absolute top-0 left-0 right-0 bottom-0 bg-gray-600 bg-opacity-50 rounded-xl">
                    <div
                      className="absolute -top-5 left-0 right-0 mx-auto w-32 rounded-full bg-gradient-to-r from-blue-600 to-cyan-600 px-3 py-2 text-sm font-medium text-white">
                      {t('homepage.pricing.pro.comingSoon')}
                    </div>
                  </div>
                )}

                <div className="p-5">
                  <h3 className="my-4 text-center font-display text-3xl font-bold">
                    {plan}
                  </h3>
                  <p className="my-5 font-display text-6xl font-semibold">
                    {price}€
                  </p>
                  <p className="text-gray-500">
                    {t('homepage.pricing.perMonth')}
                  </p>
                </div>

                <div className="flex h-20 items-center justify-center border-b border-t border-gray-200 bg-gray-50">
                  <div className="flex items-center space-x-1">
                    <p className="lg:p-2">{quote}</p>
                  </div>
                </div>

                <ul className="my-10 space-y-5 px-8 flex-grow">
                  {features.map(
                    ({ text, footnote, negative }) => (
                      <li
                        key={text}
                        className="flex space-x-5"
                      >
                        <div className="flex-shrink-0">
                          {negative ? (
                            <Minus className="h-6 w-6 text-gray-300"/>
                          ) : (
                            <Check className="h-6 w-6 text-blue-500"/>
                          )}
                        </div>
                        {footnote ? (
                          <div className="flex items-center space-x-1">
                            <p
                              className={cn(
                                'text-gray-600',
                                {
                                  'text-gray-400': negative
                                }
                              )}
                            >
                              {text}
                            </p>
                            <Tooltip
                              delayDuration={300}
                            >
                              <TooltipTrigger className="cursor-default ml-1.5">
                                <HelpCircle className="h-4 w-4 text-zinc-500"/>
                              </TooltipTrigger>
                              <TooltipContent className="w-80 p-2">
                                {footnote}
                              </TooltipContent>
                            </Tooltip>
                          </div>
                        ) : (
                          <p
                            className={cn('text-gray-600',
                              {
                                'text-gray-400': negative
                              }
                            )}
                          >
                            {text}
                          </p>
                        )}
                      </li>
                    )
                  )}
                </ul>
                <div className="border-t border-gray-200"/>
                <div className="p-5">
                  {plan === 'Free' ? (
                    <Link
                      href={
                        auth.user ? route('dashboard') : route('register')
                      }
                      className={buttonVariants({
                        className: 'w-full',
                        variant: 'secondary'
                      })}
                    >
                      {auth.user ? t('buttons.dashboard') : t('buttons.startNow')}
                      <ArrowRight className="h-5 w-5 ml-1.5"/>
                    </Link>
                  ) : auth.user ? (
                    <UpgradeButton disabled={true}/>
                  ) : (
                    <Link
                      href={route('register')}
                      className={buttonVariants({
                        className: 'w-full'
                      })}
                    >
                      {auth.user ? t('buttons.upgradeNow') : t('buttons.startNow')}
                      <ArrowRight className="h-5 w-5 ml-1.5"/>
                    </Link>
                  )}
                </div>
              </div>
            );
          })}
        </TooltipProvider>
      </div>
    </MaxWidthWrapper>
  );
};

export default Pricing;
