import {PageProps} from '@/types';
import AuroraBackground from "@/Components/ui/aurora-background";
import RootLayout from "@/Layouts/RootLayout";
import MaxWidthWrapper from "@/Components/MaxWidthWrapper";
import {useTranslation} from "react-i18next";
import GettingStarted from "@/Components/Buttons/GettingStarted";
import ImageContainer from "@/Components/ImageContainer";
import {featureList} from "@/lib/featureList";
import {Icons} from '@/Components/Icons';
import Pricing from "@/Components/Pricing";
import FAQAccordion from "@/Components/FAQAccordion";

const Welcome = ({auth}: PageProps) => {
    const {t} = useTranslation();

    return (
        <RootLayout>
            <AuroraBackground className="grainy">
                <MaxWidthWrapper className="flex flex-col items-center justify-center text-center my-6 xl:mt-32 z-10">
                    <h1 className="max-w-7xl text-5xl font-bold">
                        {t('homepage.heading_1') + ' '}
                        <span className="text-blue-600">
                            {t('homepage.heading_2')}
                        </span>
                        <br/>
                        {t('homepage.heading_3') + ' '}
                        <span className="text-blue-600">
                            {t('homepage.heading_4')}
                        </span>
                    </h1>
                    <p className="mt-8 max-w-prose text-zinc-700 sm:text-lg">
                        {t('homepage.description')}
                    </p>
                    <GettingStarted size="lg" className="mt-2"/>
                </MaxWidthWrapper>

                {/* value proposition section */}
                <div className="flex flex-col lg:flex-row lg:space-x-6">
                    <ImageContainer text={t('memory.biography')}>
                        <img
                            alt="product preview"
                            src="assets/preview/product_proposition_biography.png"
                            className="rounded-md shadow-2xl ring-1 ring-gray-900/10"
                        />
                    </ImageContainer>

                    <ImageContainer text={t('memory.events.title')}>
                        <img
                            alt="product preview"
                            src="assets/preview/product_proposition_lifeline.png"
                            className="rounded-md shadow-2xl ring-1 ring-gray-900/10"
                        />
                    </ImageContainer>
                </div>
            </AuroraBackground>

            {/* Feature section */}
            <section className="mx-auto pt-1 lg:pt-14 max-w-7xl sm:mt-56" id="features">
                <MaxWidthWrapper className="mb-8 max-w-7xl">
                    <div className="mt-6 px-4 pt-6 xl:mt-1">
                        <div className="mx-auto max-w-2xl sm:text-center">
                            <h2 className="mt-2 font-bold text-4xl text-gray-900 sm:text-5xl">
                                {t('homepage.features.title')}
                            </h2>
                            <p className="mt-4 text-lg text-gray-600">
                                {t('homepage.features.description')}
                            </p>
                        </div>
                    </div>

                    <div className="flex justify-center px-4">
                        <div
                            className="max-w-screen-xl lg:flex lg:flex-wrap lg:justify-around xl:mt-6 xl:px-8">
                            {featureList.map((item, index) => (
                                <div
                                    className="mt-10 flex w-full max-w-md lg:w-1/3 lg:flex-col lg:p-5"
                                    key={index}
                                >
                                    <div className="mr-4 lg:mr-0">
                                        {item.icon}
                                    </div>
                                    <div className="lg:mt-4">
                                        <h4 className="text-sm font-semibold text-gray-800 lg:text-lg xl:text-xl">
                                            {item.title}
                                        </h4>
                                        <p className="mt-2 text-xs leading-normal text-gray-600 lg:text-sm xl:text-base">
                                            {item.description}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </MaxWidthWrapper>
            </section>

            {/* Pricing Section */}
            <section className="mx-auto pt-1 max-w-5xl sm:mt-56" id="pricing">
                <div className="relative">
                    {/* Logo Background */}
                    <div className="absolute flex justify-center w-full">
                        <Icons.logo className="w-full h-full max-w-3xl lg:max-w-4xl"/>
                    </div>

                    {/* Pricing Content */}
                    <div className="relative z-10">
                        <Pricing auth={auth}/>
                    </div>
                </div>
            </section>

            {/* FAQ section */}
            <section className="pt-1 h-screen mx-auto mb-52 max-w-5xl sm:mt-56" id="faq">
                <div className="mb-8 mt-24 max-w-7xl">
                    <div className="mt-6 px-4 pt-6 xl:mt-1">
                        <div className="mx-auto max-w-2xl sm:text-center">
                            <h2 className="mt-2 font-bold text-4xl text-gray-900 sm:text-5xl">
                                {t('homepage.faq.title')}
                            </h2>
                            <p className="mt-4 text-lg text-gray-600">
                                {t('homepage.faq.description')}
                            </p>
                        </div>
                    </div>

                    <div className="flex justify-center px-4 mt-2">
                        <div
                            className="lg:flex lg:flex-wrap lg:justify-around mt-6">
                            <FAQAccordion/>
                        </div>
                    </div>
                </div>
            </section>
        </RootLayout>
    );
}

export default Welcome;