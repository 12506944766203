import React, {useState} from 'react';
import {Minus, Plus} from 'lucide-react';
import {useTranslation} from 'react-i18next';
import {t} from 'i18next';
import {Button} from '@/Components/ui/button';
import {Turnstile} from "@marsidev/react-turnstile";

interface AccordionItem {
    title: string;
    content: string;
}

const FAQAccordion = () => {
    const {t} = useTranslation();
    const [openIndex, setOpenIndex] = useState<number | null>(null);
    const [captchaResult, setCaptchaResult] = useState<boolean>(false)

    const handleAccordionClick = (index: number) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <div className="grid place-items-center">
            <div className="mx-auto rounded border">
                <ul className="bg-white p-6 shadow-sm">
                    {/* FAQAccordion Items */}
                    {accordionData.map((item, index) => (
                        <li
                            key={index}
                            className={`transition hover:bg-blue-50 px-4 ${
                                openIndex === index ? 'bg-blue-50' : ''
                            }`}
                        >
                            {/* FAQAccordion Header */}
                            <div
                                className="accordion-header cursor-pointer transition flex items-center h-16"
                                onClick={() => handleAccordionClick(index)}
                            >
                                {openIndex === index ? (
                                    <Minus size={24} className="mr-2"/>
                                ) : (
                                    <Plus size={24} className="mr-2"/>
                                )}
                                <h3 className="flex-1 select-none">{item.title}</h3>
                            </div>
                            {/* FAQAccordion Content */}
                            <div
                                className={`accordion-content px-5 pt-0 overflow-hidden max-h-0 ${
                                    openIndex === index ? 'max-h-full' : ''
                                }`}
                            >
                                {index === (accordionData.length - 1) ? (
                                    <div className="pb-4">
                                        <p className="leading-6 font-light">
                                            {item.content}
                                        </p>
                                        {!captchaResult && import.meta.env.PROD ? (
                                            <Turnstile
                                                siteKey="0x4AAAAAAAyCScnhthG9UYb-"
                                                className="mt-5"
                                                onSuccess={() => setCaptchaResult(true)}
                                            />
                                        ) : (
                                            <a href="mailto:help@reminara.de">
                                                <Button className="px-6 py-2 my-5">{t('buttons.sendEmail')}</Button>
                                            </a>
                                        )}
                                    </div>
                                ) : (
                                    <p className="leading-6 font-light pb-9 ">
                                        {item.content}
                                    </p>
                                )}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

// Data for accordion items
const accordionData: AccordionItem[] = [
    {
        title: t('homepage.faq.reasonToUse.title'),
        content: t('homepage.faq.reasonToUse.description')
    },
    {
        title: t('homepage.faq.reasonToTrust.title'),
        content: t('homepage.faq.reasonToTrust.description')
    },
    {
        title: t('homepage.faq.reasonToChoose.title'),
        content: t('homepage.faq.reasonToChoose.description')
    },

    {
        title: t('homepage.faq.questionsRemain.title'),
        content: t('homepage.faq.questionsRemain.description')
    }
];

export default FAQAccordion;
